import "../../../../../../node_modules/.pnpm/@vanilla-extract+webpack-plugin@2.3.15_@types+node@20.17.10_babel-plugin-macros@3.1.0_terser@_up4y6fjbw7lo554abzy63tgjke/node_modules/@vanilla-extract/webpack-plugin/vanilla.virtual.css?%7B%22fileName%22%3A%22src%2Fcomponents%2Fsections%2Fsplash-section%2FSplashSection.css.ts.vanilla.css%22%2C%22source%22%3A%22%23H4sIAAAAAAAAA7VUy26DMBC85yt8qZQcXJmHwZBL%2F6RywQVLvAouUFX59wrbJDaQkh569Hp2ZnbW8nMqqPgQCHwfAEh51xT0KwZZy9PzAYCMNjHoaXuE8DUMyrxnfXeaLpqCJgxywcouBgmrBGunsmCjgLTgWWVWG5qmvMqgqJdsw3A6Hy6HZ2XCkSY0OgZoodyaWFdih5wLBruGJiwGVT20tDEwnsRseyrpCAeeijwGEULNeH5Y2d%2BO6k4iWsNB6Gk65oxnuZjORIsaRhyinFyl8B2puuOC11UMWlZQwXv2dwNBiC2pQGV%2FZaZvXV18CmZRuJ7yPJMQF6uC3CzENmUoKQv2LmIA3cVoRF62imd1G6nt7mW3bVeZcYhNSR8Y0J1fwiqll5KlnIIuaRmrAK1ScDQX5wW4GU9SwXolNyYc6qguNwxeYHzPXWECjdFK2HEUxOhy3LkkJ0cLhlAz6EVgtNIgGjFvYwMS3Rvnuvto1UNt62FAVtZvA%2B9F7E%2FrtBJGmt76XXzyDwnOw5uXe37lp%2FL7i3D7YedBeMjfSxX7aDmO0bXnMli5XFrYcLlwQCKFMHqcuefyAz7w3EFfBgAA%22%7D"
import { createRuntimeFn as _7a468 } from '@vanilla-extract/recipes/createRuntimeFn';
export var containerCx = 'dtatqt0';
export var gradientCx = 'dtatqt9';
export var imageWrapperCx = 'dtatqta';
export var linesStyle = _7a468({defaultClassName:'dtatqt6',variantClassNames:{position:{left:'dtatqt7',right:'dtatqt8'}},defaultVariants:{},compoundVariants:[]});
export var noWrapCx = 'dtatqt2';
export var screenshotInnerWrapperCx = 'dtatqt5';
export var screenshotOuterWrapperCx = 'dtatqt4';
export var subCx = 'dtatqt3';
export var titleCx = 'dtatqt1';