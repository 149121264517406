import { DemoModal } from '@/components/DemoModal'
import { DemoModalToggle } from '@/components/DemoModalToggle'
import { Container } from '@/components/container/Container'
import { useViewport } from '@/contexts/ViewportContext'
import Trans from 'next-translate/Trans'
import useTranslation from 'next-translate/useTranslation'
import Image from 'next/image'
import TCDashboardJP from 'public/images/tc-dashboard-jp.png'
import TCDashboardEN from 'public/images/tc-dashboard.png'
import { FC, useState } from 'react'
import { Box } from 'ui-v2/components/Box'
import { Text } from 'ui-v2/components/Text'
import { Gradient } from './Gradient'
import { LeftLines } from './LeftLines'
import { RightLines } from './RightLines'
import {
  containerCx,
  gradientCx,
  imageWrapperCx,
  linesStyle,
  noWrapCx,
  screenshotInnerWrapperCx,
  screenshotOuterWrapperCx,
  subCx,
  titleCx,
} from './SplashSection.css'

const d = {
  mainTitle: 'tensor-website-landing:main-title',
  mainSub: 'tensor-website-landing:main-sub',
  mainCta: 'tensor-website-landing:main-cta',
  splashImgAlt: 'tensor-website-landing:seo.splash-img-alt',
}

export const SplashSection: FC = () => {
  const [isModalOpen, setIsModalOpen] = useState(false)
  const { width } = useViewport()
  const showBackgroundElements = width > 900
  const { t, lang } = useTranslation()

  return (
    <Container as="section">
      <div className={containerCx}>
        <Text variant="displayLg" as="h1" className={titleCx}>
          <Trans
            i18nKey={d.mainTitle}
            components={{
              br: <br />,
              nowrap: <span className={noWrapCx} />,
            }}
          />
        </Text>
        <Text variant="paragraphXl" color="colorsTextNeutralDefaultLowContrast" className={subCx}>
          {t(d.mainSub)}
        </Text>
        <DemoModalToggle
          openModal={() => setIsModalOpen(true)}
          buttonText={t(d.mainCta)}
          color="primary"
        />
        <DemoModal isOpen={isModalOpen} setIsOpen={setIsModalOpen} />
        <div className={screenshotOuterWrapperCx}>
          <div className={screenshotInnerWrapperCx}>
            {showBackgroundElements && (
              <>
                <LeftLines className={linesStyle({ position: 'left' })} />
                <Gradient className={gradientCx} />
                <RightLines className={linesStyle({ position: 'right' })} />
              </>
            )}
            <Box className={imageWrapperCx}>
              <Image
                src={lang === 'ja' ? TCDashboardJP : TCDashboardEN}
                fill
                sizes="(max-width: 600px) 90vw, 80vw"
                alt={t(d.splashImgAlt)}
              />
            </Box>
          </div>
        </div>
      </div>
    </Container>
  )
}
