import { DemoModal } from '@/components/DemoModal'
import { DemoModalToggle } from '@/components/DemoModalToggle'
import { FlatTextCTA } from '@/components/ctas/FlatTextCTA'
import { HowItWorksSection } from '@/components/how-it-works/HowItWorksSection'
import { AllSystemsGoSection } from '@/components/sections/AllSystemsGoSection'
import { ElectricityCompaniesSection } from '@/components/sections/ElectricityCompaniesSection'
import { SplashSection } from '@/components/sections/splash-section/SplashSection'
import { CTASectionWrapper } from '@/components/wrappers/CTASectionWrapper'
import { WebsiteLayout } from '@/layouts/WebsiteLayout'
import useTranslation from 'next-translate/useTranslation'
import { FC, useState } from 'react'
import { CONTACT_URL } from 'src/assets/links'
import { LinkButton } from 'ui-v2/components/Button'
import { Separator } from 'ui-v2/components/Separator'
import {
  containerCx,
  ctaButtonsContainerCx,
  ctaSectionWrapperCx,
  linkCx,
  separatorCx,
} from './Home.css'

export interface HomeProps {}

export const d = {
  mainTitle: 'tensor-website-landing:main-title',
  mainSub: 'tensor-website-landing:main-sub',
  mainCta: 'tensor-website-landing:main-cta',
  ctaTitle: 'tensor-website-landing:cta-title',
  ctaContactUs: 'tensor-website-landing:cta-contact-us',
  ctaDemo: 'tensor-website-landing:cta-demo',
}

export const Home: FC<HomeProps> = () => {
  const { t } = useTranslation()
  const [isModalOpen, setIsModalOpen] = useState(false)

  return (
    <WebsiteLayout>
      <div className={containerCx}>
        <SplashSection />
        <ElectricityCompaniesSection />
        <AllSystemsGoSection />
        <div className={separatorCx}>
          <Separator orientation="horizontal" spacing="none" />
        </div>
        <HowItWorksSection />
        <div className={separatorCx}>
          <Separator orientation="horizontal" spacing="none" />
        </div>
        <div className={ctaSectionWrapperCx}>
          <CTASectionWrapper>
            <FlatTextCTA titleI18nKey={d.ctaTitle}>
              <div className={ctaButtonsContainerCx}>
                <DemoModalToggle openModal={() => setIsModalOpen(true)} color="primary" />
                <DemoModal isOpen={isModalOpen} setIsOpen={setIsModalOpen} />
                <LinkButton
                  href={CONTACT_URL}
                  color="primary"
                  size="lg"
                  variant="soft"
                  className={linkCx}
                >
                  {t(d.ctaContactUs)}
                </LinkButton>
              </div>
            </FlatTextCTA>
          </CTASectionWrapper>
        </div>
      </div>
    </WebsiteLayout>
  )
}
