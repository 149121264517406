import Trans from 'next-translate/Trans'
import useTranslation from 'next-translate/useTranslation'
import { FC } from 'react'
import { Text } from 'ui-v2/components/Text'
import { containerCx, noWrapCx, subCx } from './HowItWorksHeading.css'
import { d } from './dict'

export interface HowItWorksHeadingProps {}

export const HowItWorksHeading: FC<HowItWorksHeadingProps> = () => {
  const { t } = useTranslation()
  return (
    <div className={containerCx}>
      <Text as="h2" variant="displaySm">
        <Trans i18nKey={d.title} components={{ nowrap: <span className={noWrapCx} /> }} />
      </Text>
      <Text variant="paragraphXl" color="colorsTextNeutralDefaultLowContrast" className={subCx}>
        {t(d.sub)}
      </Text>
    </div>
  )
}
